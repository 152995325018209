<template>
  <div class="commande" v-if="load">
<!--    {{ commande }}-->
    <div class="step" v-if="step === 'En attente'">
      <lottie-animation class="ico" :width="650" :height="650" :speed="0.8" path="lottie/commande/wait8.json" />
    </div>
    <div class="step" v-else-if="step === 'En préparation'">
      <lottie-animation class="ico" :width="480" :height="480" path="lottie/commande/prepare2.json" />
    </div>
    <div class="step" v-else-if="step === 'En cours de livraison'">
      <lottie-animation class="ico" :width="400" :height="400" path="lottie/commande/delivery.json" />
    </div>
    <router-link tag="div" :to="{ name: 'user_compte_commandes' }" class="step" v-else-if="step === 'Prête à retirer'">
      <lottie-animation class="ico" :width="400" :height="400" path="lottie/commande/retrait_ok.json" />
    </router-link>


    <div class="t" v-if="commande">{{ status_format(commande.status) }}
      <div class="d">
        <span v-if="commande.livraison.type === 'livraison'" class="arrivee">Arrivée estimée : {{ $roundHour(commande.at, commande.b_delay) }}</span>
        <span v-if="commande.livraison.type === 'retrait'">{{ commande.livraison.type }} </span>
        <span v-if="commande.livraison.type === 'livraison'">{{ commande.livraison.adresse.adresse.streetNumber }} {{ commande.livraison.adresse.adresse.streetName }}, {{ commande.livraison.adresse.adresse.city }}</span>
        <span v-if="commande.livraison.type === 'retrait'">{{ commande.livraison.hour }}</span>
        <span class="resto">{{ commande.b_name }} • <a :href="'tel://' + commande.b_national_phone_number">{{ commande.b_national_phone_number }}</a></span>
      </div>
    </div>

    <div class="progressGrp">
      <div class="progress-line" :data-yes="step === 'En préparation' || step === 'En cours de livraison'" v-if="step !== 'Prête à retirer'"></div>
      <div class="progress-line" :data-yes="step === 'En cours de livraison'" :data-no="step === 'En attente'" v-if="step !== 'Prête à retirer'"></div>
      <div class="progress-line" v-if="step !== 'Prête à retirer'" :data-no="step === 'En préparation' || step === 'En attente'"></div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import NoSleep from "nosleep.js";
let noSleep = new NoSleep();
export default {
  name: "commande",
  data() {
    return {
      commande: null,
      step: null,
      load: false,
      audio: {}
    }
  },
  components: { LottieAnimation },
  created() {
    this.$loader = true
    this.init()
    this.audio["step"] = new Audio("/audio/step.mp3");
  },
  mounted() {
    document.addEventListener('click', function enableNoSleep() {
      document.removeEventListener('click', enableNoSleep, false);
      noSleep.enable();
    }, false);
  },
  sockets: {
    connect() {
      this.$socket.client.emit('join', 'boutique_commande_' + this.$route.params.commande_id)
    },
    refresh() {
      this.init()
    }
  },
  methods: {
    init() {
      this.$http.get('/purchase/' + this.$route.params.commande_id).then((commande) => {
        this.$loader = false
        this.commande = commande.data
        console.log(this.commande)
        this.step = this.commande.status
        if(this.load === true)
          this.audio["step"].play()
        
        this.load = true
        
        this.$socket.client.emit('join', 'boutique_commande_' + this.$route.params.commande_id)
        
        if(this.commande.status === 'Livrée') {
          this.$router.push({ name: 'user_compte_commandes' })
        }
        if(this.commande.status === 'Remboursée') {
          this.$router.push({ name: 'user_compte_commandes' })
        }
        if(this.commande.status === 'Retirée') {
          this.$router.push({ name: 'user_compte_commandes' })
        }
        
      }).catch(() => {
        this.$loader = false
        this.$router.push({ name: 'user_compte_commandes' })
      })
    },
    status_format(s) {
      if(s === 'En préparation')
        return 'Commande en préparation.'
      else if(s === 'En attente')
        return 'Confirmation en cours.'
      else if(s === 'Prête à retirer')
        return 'Votre commande est prête !'
      else 
        return s
    }
  }
}
</script>

<style scoped>
  .step { padding: 16px; height: calc((var(--vh, 1vh) * 100) - 205px); position: relative; overflow: hidden }
  .step .ico { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) }
  .t { font-weight: 700; font-size: 18px; position: absolute; bottom: 36px; left: 0; right: 0; padding: 0 16px }
  .t .d { font-size: 16px; display: flex; flex-direction: column; color: var(--black-sub); font-weight: 500; margin-top: 4px; }
  .t .d span { text-transform: capitalize; }
  .t .d span.arrivee { text-transform: none; color: #787482; font-weight: 600; padding-bottom: 12px; font-size: 14px }
  .t .d span.resto { margin-top: 12px; }
  
  .progressGrp { position: absolute; bottom: 16px; left: 16px; right: 16px; display: flex; }

  .progress-line, .progress-line:before {
    height: 4px;
    width: 100%;
    margin: 0;
  }
  .progress-line {
    background-color: var(--gris); border-radius: 6px; overflow: hidden;
    display: -webkit-flex;
    display: flex; margin-right: 6px;
  }
  .progress-line:last-child { margin-right: 0; }
  .progress-line:before {
    background-color: var(--orange);
    content: '';
    animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  .progress-line[data-no]:before { animation: none!important; display: none }
  .progress-line[data-yes]:before { animation: none!important; display: none }
  .progress-line[data-yes] { background: var(--orange) }
  
  @keyframes running-progress {
    0% { margin-left: 0; margin-right: 100%; }
    50% { margin-left: 0%; margin-right: 0%; }
    100% { margin-left: 100%; margin-right: 0; }
  }


  @media only screen and (min-width: 800px) {
    .commande { width: 800px; margin: auto; height: calc(100vh - 80px); position: relative; }
    
  }
  
</style>